<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer">
    </v-navigation-drawer>

    <v-app-bar>
      <v-app-bar-nav-icon 
        @click="drawer = !drawer"
        >
      </v-app-bar-nav-icon>

      <v-toolbar-title>Shape</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
  export default {
    data: () => ({ drawer: null }),
  }
</script>