<template>
  <h1 class="text-center">Quel est ton style d'évangelisation ?</h1>

  <v-card class="mb-5">
    <v-card-title class="text-center">Mes infos</v-card-title>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="12" xs6>
          <v-text-field label="Nom" v-model="nom"></v-text-field>
        </v-col>
        <v-col cols="12" xs6>
          <v-text-field label="Prénom" v-model="prenom"></v-text-field>
        </v-col>
        <v-col cols="12" xs12>
          <v-text-field label="Email" v-model="email"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <h2 class="text-center">Le test</h2>

  <v-card class="mb-5" elevation="0" v-for="question in this.questions" :key="question.id">
    <v-card-text>
      <h3 class="text-center">
        {{ question.question }}
        <br />
        <small>{{ question.id }} / {{ questionsLength }}</small>
      </h3>
      <v-row align="center" justify="center">
        <v-col cols="auto">
          Pas du tout d'accord
        </v-col>
        <v-col cols="auto" v-for="responseOption in responseOptions" :key="responseOption.value">
          <v-btn @click="answerQuestion(question.id, responseOption.value)" icon rounded="xl"
            :variant="(question.answer === responseOption.value) ? 'flat' : 'outlined'" :color="responseOption.color"
            :density="responseOption.density"></v-btn>
        </v-col>
        <v-col cols="auto">
          Tout a fait d'accord
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <div class="text-center">
    <v-btn size="x-large" color="green" @click="submitData()">
      Obtenir mes resultats
    </v-btn>
  </div>

  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">Congratulations</v-card-title>
      <v-card-text>
        Vous avez repondu a toutes les questions, voici vos resultats :
        <ul>
          <li v-for="(result, index) in results" :key="index">
            {{ result.style }} ({{ result.result }}pts)
          </li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" @click="reset()">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'GospelTest',
  created() {
    this.displayedQuestions = this.questions.slice(0, this.nextQuestionCount);
  },
  computed: {
    results() {
      let styles = [
        'LA CONFRONTATION',
        'L\'APPROCHE INTELLECTUELLE',
        'LE TÉMOIGNAGE',
        'LES RELATIONS PERSONNELLES',
        'L\'INVITATION',
        'PAR LES ACTES',
      ];

      let results = [];
      let i = 0;
      for (let style of styles) {
        let answers = this.questions.slice(i, i + 6);
        results.push({
          style: style,
          result: answers.reduce((acc, obj) => acc + obj.answer, 0)
        });
        i += 6;
      }
      return results.sort((a, b) => b.result - a.result);
    },
  },
  methods: {
    answerQuestion(questionId, answer) {
      this.questions[questionId - 1].answer = answer;
    },
    submitData() {
      // construct the form data
      let formData = new FormData();
      formData.append('prenom', this.prenom);
      formData.append('nom', this.nom);
      formData.append('email', this.email);
      formData.append('test', 'gospel');
      formData.append('responses', JSON.stringify(this.questions));

      // send a POST request
      axios.post('/save_responses.php', formData)
        .then(response => {
          if (response.data.success) {
            console.log('Data saved successfully');
          } else {
            console.error('Error saving data:', response.data.error);
          }
          this.dialog = !this.dialog;
        })
        .catch(error => {
          console.error('Request failed:', error);
          // handle network errors here...
        });
    },
    reset() {
      this.dialog = false;
      this.nom = '';
      this.prenom = '';
      this.email = '';
      this.questions.forEach(question => question.answer = null);
      this.$router.push('/');
    }
  },
  data() {
    return {
      questions: [
        { id: 1, question: " Dans les conversations, j’aime aborder directement les sujets, sans trop parler ou faire de petites discussions.", answer: null },
        { id: 2, question: " C’est difficile pour moi de quitter une librairie ou une bibliothèque sans une pile de livres qui m’aident à comprendre les sujets de société sur lesquels on débat actuellement.", answer: null },
        { id: 3, question: " Je partage souvent des récits d’expériences personnelles pour illustrer un point que j’essaye de communiquer.", answer: null },
        { id: 4, question: " Je suis quelqu’un qui aime les gens ; j’accorde beaucoup d’importance à l’amitié.", answer: null },
        { id: 5, question: " Je prends plaisir à inclure ou à ajouter de nouvelles personnes aux activités dans lesquelles je suis impliqué. ", answer: null },
        { id: 6, question: " Je vois les besoins dans la vie des personnes que souvent les autres ne voient pas.", answer: null },
        { id: 7, question: " Je n’ai pas peur de remettre les gens à leur place si nécessaire.", answer: null },
        { id: 8, question: " J’aime analyser les choses.", answer: null },
        { id: 9, question: " Je m’identifie facilement aux autres personnes, en utilisant des phrases comme « Je pense la même chose » ou « Je ressens la même chose ».", answer: null },
        { id: 10, question: "D’autres personnes ont remarqué ma capacité à me faire de nouveaux amis.", answer: null },
        { id: 11, question: "Pour être honnête, même quand je connais les réponses, je suis plus à l’aise quand quelqu’un de plus qualifié que moi explique la foi à mes amis.", answer: null },
        { id: 12, question: "Je me sens très satisfait d’aider les autres si ce n’est pas quelque chose fait en public.", answer: null },
        { id: 13, question: "Je n’ai aucun problème à confronter mes amis avec la vérité, même si cela signifie mettre en péril notre relation.", answer: null },
        { id: 14, question: "Je m’intéresse très naturellement aux conversations qui parlent du progrès des gens (ou de leur manque de progrès) dans leur vie spirituelle.", answer: null },
        { id: 15, question: "Quand j’explique aux gens comment je suis devenu chrétien, je vois qu’ils sont intéressés.", answer: null },
        { id: 16, question: "Je préfère discuter de sujets personnels plutôt que de concepts théologiques.", answer: null },
        { id: 17, question: "Si je suis au courant d’un événement d’évangélisation de qualité que mes amis vont apprécier, je fais tous les efforts pour les inviter.", answer: null },
        { id: 18, question: "Je préfère montrer mon amour à travers mes actes plutôt qu’avec mes mots.", answer: null },
        { id: 19, question: "Je crois que le véritable amour signifie souvent dire à quelqu’un la vérité, même si cela peut blesser.", answer: null },
        { id: 20, question: "Je prends plaisir à discuter et à débattre à propos de questions difficiles.", answer: null },
        { id: 21, question: "Je partage mes erreurs avec les autres, spécialement quand cela les aide à comprendre les solutions que j’ai trouvées.", answer: null },
        { id: 22, question: "Je préfère avoir des discussions sur la vie des gens avant de partager les détails de leurs croyances.", answer: null },
        { id: 23, question: "Ma tendance est d’attendre pour inviter des gens aux évènements ponctuels d’évangélisation comme des concerts, des dîners, des campagnes d’évangélisation, …", answer: null },
        { id: 24, question: "Quand les gens sont spirituellement fermés, je trouve que ma démonstration silencieuse de l’amour chrétien les rend parfois plus réceptifs.", answer: null },
        { id: 25, question: "Une phrase de motivation qui me décrit est « Faire quelque chose d’important, même si cela implique des risques, mais faire quelque chose. »", answer: null },
        { id: 26, question: "Je suis souvent frustré avec les gens qui utilisent des arguments faibles ou une logique pauvre.", answer: null },
        { id: 27, question: "Les gens semblent intéressés par les récits que j’ai à raconter au sujet de ce qui m’est arrivé.", answer: null },
        { id: 28, question: "J’apprécie de parler avec mes amis.", answer: null },
        { id: 29, question: "Je suis toujours en train de chercher à mettre en relation les besoins et intérêts de mes amis avec différents événements ou livres dont ils vont pouvoir profiter et qu’ils vont apprécier.", answer: null },
        { id: 30, question: "Je préfère aider quelqu’un dans le nom de Christ, plutôt qu’être engagé dans des discussions religieuses.", answer: null },
        { id: 31, question: "Parfois, j’ai des ennuis à cause de mon manque de sensibilité et de tact, et à cause de la façon dont j’interagis avec les autres.", answer: null },
        { id: 32, question: "J’aime entendre et comprendre les raisons des opinions des autres.", answer: null },
        { id: 33, question: "Je suis toujours surpris de voir la façon dont Dieu m’a conduit à Lui faire confiance, et je suis motivé à le partager.", answer: null },
        { id: 34, question: "Généralement, les gens me trouvent sociable, sensible et amical.", answer: null },
        { id: 35, question: "Quelque chose de motivant dans ma semaine serait d'emmener un invité à un événement ponctuel de l'église.", answer: null },
        { id: 36, question: "Ma tendance est d’être plus pratique et orienté vers l’action, plutôt qu’être orienté vers les idées philosophiques.", answer: null },
      ],
      responseOptions: [
        { value: 1, color: 'blue', density: 'default' },
        { value: 2, color: 'blue-lighten-3', density: 'comfortable' },
        { value: 3, color: 'purple-lighten-3', density: 'comfortable' },
        { value: 4, color: 'purple', density: 'default' }
      ],
      questionsLength: 36,
      dialog: false,
      nom: null,
      prenom: null,
      email: null,
    }
  },
}
</script>

<style>
.v-card.inactive {
  opacity: 0.3;
}
</style>