<template>
  <h1 class="text-center">SHAPE</h1>

  <v-card class="mb-5">
    <v-card-text>
      <h1>Découvre tes dons</h1>

      <p>
        Ephésiens 2/10<br />
        <em>C’est Dieu qui nous a faits ; nous avons été créés en Jésus-Christ pour des œuvres bonnes que Dieu a préparées
          d’avance afin que nous les pratiquions.</em>
      </p>

      <p>
        Ce test est la première partie (Dons offerts par Dieu) d’un test plus complet appelé « SHAPE » (FORME).
      </p>

      <p>
        « SHAPE » est un acronyme en anglais qui décrit cinq aspects de la façon qu’a Dieu de préparer une personne au
        service pour lequel Il l’appelle :
      </p>

      <ul>
        <li><b>Spiritual Gifts = Dons offerts par Dieu</b><br />Une capacité particulière donnée par Dieu à chaque croyant
          pour Son service et pour le bénéfice de Son Eglise, le Corps de Christ.</li>
        <li><b>Heart = Cœur / Passion</b><br />Un désir suscité par Dieu qui insuffle une motivation permettant au croyant
          de s’impliquer dans un ministère qui
          compte pour le royaume de Dieu.</li>
        <li><b>Abilities = Habiletés, capacités</b><br />Les talents et compétences donnés par Dieu à chaque être humain
          pour Sa gloire (que les personnes aiment Dieu ou
          pas) qui prouvent que Sa grâce ne fait exception de personne.</li>
        <li><b>Personality = Personnalité</b><br />L’approche personnelle et dynamique que tu as a vis-à-vis de la vie et
          des relations.</li>
        <li><b>Experiences = Expériences</b><br />Les événements et situations que Dieu utilise dans la vie pour te former
          et te faire devenir qui tu es aujourd’hui.</li>
      </ul>

      <h2>Découvre tes Dons</h2>

      <p>
        Les dons offerts par Dieu sont des habiletés particulières données par le Saint-Esprit à toutes celles et ceux qui
        choisissent de suivre Jésus-Christ. Ils sont donnés comme une bénédiction et pour que les autres en bénéficient à
        travers le service. Plus tu serviras Dieu, plus tu seras en mesure d’identifier tes dons. Ces dons sont différents
        des talents naturels. Découvrir quel est ton don est une première étape cruciale pour trouver la place où Dieu
        veut
        t’utiliser, une place où tu pourras expérimenter un sentiment d’accomplissement et de complétude unique !
      </p>

      <p>Les façons de découvrir nos dons :</p>

      <ul>
        <li>Expérimenter – Il est plus facile de découvrir son don en pratiquant un ministère que de découvrir son
          ministère
          en pratiquant un don.</li>
        <li>Lecture et étude – Il y a un nombre conséquent de livres qui parlent des dons spirituels.</li>
        <li>Faire des tests – Comme ce test S.H.A.P.E.</li>
        <li>Demander le retour des autres – Les autres verront souvent des dons en nous que nous ne percevons pas
          nous-mêmes,
          ou confirmerons ceux que nous pensons avoir reçus.</li>
      </ul>

      <p>Références bibliques : 1 Corinthiens 12; 1 Pierre 4/9-11; Romains 12/1-8; Ephésiens 4/7-13
        Prêt / Prête à démarrer ce test ?</p>

      <p>
        Etape Une : demande à Dieu de t’accorder de la sagesse dans le discernement de tes dons.
      </p>
      <p>
        Etape Deux : note la ou les réponses qui font le plus écho à ton expérience personnelle dans le service, ou qui,
        selon toi, te décrivent le mieux. Il y a 133 questions qui vont t’aider à identifier tes dons.
      </p>
      <p>
        Attention : il n’y a pas de bonne ou de mauvaise réponse. Ne sois pas trop humble (comme si tu n’avais aucun don)
        ou
        trop confiant (comme si tu avais tous les dons) !
      </p>

      <h3>Consignes :</h3>

      <p>Réponds à chaque affirmation du Test sur les Dons offerts par Dieu qui suit, en inscrivant à côté de
        l’affirmation
        le numéro correspondant selon les critères suivants :</p>

      <p>3 = Constamment, toujours vrai<br />
        2 = La plupart du temps, généralement vrai<br />
        1 = Parfois, une fois de temps en temps<br />
        0 = Pas du tout, jamais</p>

      <p>
        Important : Réponds en fonction de qui tu es, non en fonction de qui tu voudrais être ou pense devoir être. Les
        affirmations suivantes sont-elles vraies pour toi ? Quelle expérience as-tu ? A quel point ces affirmations
        reflètent-elles tes tendances habituelles ?
      </p>

      <p>
        NB : Les dons de guérison, d’interprétation, de miracles, et le parler en langues ne sont pas inclus dans ce test
        car leur présence dans la vie d’un croyant est en général évidente.
      </p>

      <h2>COMMENÇONS !</h2>

    </v-card-text>
  </v-card>


  <v-card class="mb-5">
    <v-card-title class="text-center">Mes infos</v-card-title>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="12" xs6>
          <v-text-field label="Nom" v-model="nom"></v-text-field>
        </v-col>
        <v-col cols="12" xs6>
          <v-text-field label="Prénom" v-model="prenom"></v-text-field>
        </v-col>
        <v-col cols="12" xs12>
          <v-text-field label="Email" v-model="email"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <h2 class="text-center">Le test</h2>

  <v-card class="mb-5" elevation="0" v-for="question in this.questions" :key="question.id">
    <v-card-text>
      <h3 class="text-center">
        {{ question.question }}
        <br />
        <small>{{ question.id }} / {{ questionsLength }}</small>
      </h3>
      <v-row align="center" justify="center">
        <v-col cols="auto">
          Pas du tout d'accord
        </v-col>
        <v-col cols="auto" v-for="responseOption in responseOptions" :key="responseOption.value">
          <v-btn @click="answerQuestion(question.id, responseOption.value)" icon rounded="xl"
            :variant="(question.answer === responseOption.value) ? 'flat' : 'outlined'" :color="responseOption.color"
            :density="responseOption.density"></v-btn>
        </v-col>
        <v-col cols="auto">
          Tout a fait d'accord
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <div class="text-center">
    <v-btn size="x-large" color="green" @click="submitData()">
      Obtenir mes resultats
    </v-btn>
  </div>

  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">Congratulations</v-card-title>
      <v-card-text>
        Vous avez repondu a toutes les questions, voici vos resultats :
        <ul>
          <li v-for="(result, index) in results" :key="index">
            {{ result.don }} ({{ result.result }}pts)
          </li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" @click="reset()">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ShapeTest',
  created() {
    this.displayedQuestions = this.questions.slice(0, this.nextQuestionCount);
  },
  computed: {
    results() {
      let dons = [
        'Administration',
        'Pionnier et implanteur d\'églises',
        'Artisanat',
        'Communication créative',
        'Discernement',
        'Encouragement',
        'Evangélisation',
        'Foi',
        'Générosité, don de finances',
        'Aide, soutien',
        'Hospitalité',
        'Intercession',
        'Savoir',
        'Leadership',
        'Compassion',
        'Prophétie',
        'Pastorat',
        'Enseignement',
        'Sagesse',
      ];

      let results = [];
      let i = 0;
      for (let don of dons) {
        let answers = this.questions.slice(i, i + 9);
        results.push({
          don: don,
          result: answers.reduce((acc, obj) => acc + obj.answer, 0)
        });
        i += 9;
      }
      return results.sort((a, b) => b.result - a.result);
    },
  },
  methods: {
    answerQuestion(questionId, answer) {
      this.questions[questionId - 1].answer = answer;
    },
    submitData() {
      // construct the form data
      let formData = new FormData();
      formData.append('prenom', this.prenom);
      formData.append('nom', this.nom);
      formData.append('email', this.email);
      formData.append('test', 'shape');
      formData.append('responses', JSON.stringify(this.questions));

      // send a POST request
      axios.post('/save_responses.php', formData)
        .then(response => {
          if (response.data.success) {
            console.log('Data saved successfully');
          } else {
            console.error('Error saving data:', response.data.error);
          }
          this.dialog = !this.dialog;
        })
        .catch(error => {
          console.error('Request failed:', error);
          // handle network errors here...
        });
    },
    reset() {
      this.dialog = false;
      this.nom = '';
      this.prenom = '';
      this.email = '';
      this.questions.forEach(question => question.answer = null);
      this.$router.push('/');
    }
  },
  data() {
    return {
      questions: [
        { id: 1, question: "J’aime organiser des événements, des tâches et des équipes de personnes.", answer: null },
        { id: 2, question: "J’aimerais implanter des églises dans des lieux où il n’en existe pas actuellement.", answer: null },
        { id: 3, question: "J’aime travailler de façon créative à partir de bois, de tissus, de peintures, de métaux, de verre ou d’autres matériaux. ", answer: null },
        { id: 4, question: "J’aime amener les gens à découvrir une nouvelle facette de Dieu à travers différentes formes d’art.", answer: null },
        { id: 5, question: "Je peux immédiatement faire la distinction entre une vérité spirituelle et une erreur spirituelle, entre ce qui vient de Dieu et ce qui ne vient pas de Lui.", answer: null },
        { id: 6, question: "J’ai une faculté à percevoir le potentiel des gens.", answer: null },
        { id: 7, question: "Je communique le message de l’Evangile aux autres avec clarté et efficacité.", answer: null },
        { id: 8, question: "Il m’est naturel et facile de croire que Dieu répond à mes prières.", answer: null },
        { id: 9, question: "Je donne libéralement et avec joie aux personnes dans le besoin et aux projets qui requièrent un soutien financier.", answer: null },
        { id: 10, question: "J’aime travailler en coulisse pour apporter mon soutien au travail des autres.", answer: null },
        { id: 11, question: "Je conçois ma maison comme étant un endroit fait pour œuvrer auprès des personnes dans le besoin.", answer: null },
        { id: 12, question: "Je recueille des sujets de prière venant d’autres personnes et prie pour ces sujets avec constance.", answer: null },
        { id: 13, question: "Des personnes viennent vers moi pour connaître mon point de vue sur un passage de la Parole de Dieu ou sur une vérité biblique.", answer: null },
        { id: 14, question: "J’ai la capacité de motiver les autres pour qu’ils remplissent des objectifs.", answer: null },
        { id: 15, question: "J’ai de l’empathie pour les personnes qui souffrent et désire les aider dans leur processus de guérison.", answer: null },
        { id: 16, question: "J’ai une façon de parler qui produit une conviction et un changement dans la vie des autres.", answer: null },
        { id: 17, question: "J’aime prendre du temps pour nourrir et prendre soin des autres.", answer: null },
        { id: 18, question: "Je suis capable de communiquer sur l’œuvre de Dieu de façon efficace.", answer: null },
        { id: 19, question: "Je suis souvent sollicité par les autres pour leur donner des conseils quant à des questions spirituelles ou personnelles.", answer: null },
        { id: 20, question: "J’apporte du soin, je suis minutieux et doué pour fignoler les choses dans les détails.", answer: null },
        { id: 21, question: "Je suis attiré par l’idée de servir dans un autre pays ou dans une autre communauté ethnique.", answer: null },
        { id: 22, question: "Je suis doué pour travailler avec différents types d’outils. ", answer: null },
        { id: 23, question: "J’aime développer et utiliser mes talents artistiques (art, théâtre, musique, photographie, etc...).", answer: null },
        { id: 24, question: "Je suis fréquemment dans la capacité de jauger le caractère d’une personne en me basant sur mes premières impressions.", answer: null },
        { id: 25, question: "J’aime rassurer et donner de la force à celles et ceux qui sont découragés.", answer: null },
        { id: 26, question: "Je cherche systématiquement des opportunités pour établir un contact avec les non-chrétiens.", answer: null },
        { id: 27, question: "J’ai confiance dans les infaillibles provisions et secours de Dieu, même dans les moments difficiles.", answer: null },
        { id: 28, question: "Je donne plus que ma dîme de façon à voir l’œuvre du royaume de Dieu s’accomplir.", answer: null },
        { id: 29, question: "J’aime effectuer des tâches routinières qui soutiennent un ministère.", answer: null },
        { id: 30, question: "J’aime rencontrer de nouvelles personnes et les aider à se sentir accueillies.", answer: null },
        { id: 31, question: "J’aime passer de longs moments dans la prière et recevoir des directions de Dieu quant à ma façon de prier et quant aux sujets de prière en eux-mêmes. ", answer: null },
        { id: 32, question: "Je reçois des pensées qui viennent du Saint-Esprit, que je n’ai pas pu obtenir par des moyens naturels.", answer: null },
        { id: 33, question: "J’ai la capacité d’influencer les autres afin qu’ils accomplissent une vision.", answer: null },
        { id: 34, question: "Je peux patiemment soutenir les personnes qui passent par des expériences douloureuses, alors qu’elles essaient de regagner de la stabilité dans leur vie.", answer: null },
        { id: 35, question: "Je sens qu’il est de ma responsabilité de confronter les autres à la vérité.", answer: null },
        { id: 36, question: "J’ai de la compassion pour les chrétiens rétrogrades et je veux les protéger.", answer: null },
        { id: 37, question: "Je suis capable de passer du temps dans l’étude de la Parole de Dieu, sachant que présenter aux gens la vérité qu’elle contient, fera une vraie différence dans leur vie.", answer: null },
        { id: 38, question: "J’arrive souvent à trouver des solutions simples et pratiques au beau milieu d’un conflit ou de la confusion.", answer: null },
        { id: 39, question: "Je suis capable d’établir clairement des objectifs et d’élaborer des stratégies ou des plans pour les accomplir.", answer: null },
        { id: 40, question: "Je suis désireux de prendre une part active à l’implantation d’une nouvelle église.", answer: null },
        { id: 41, question: "J’aime fabriquer des choses qui seront utiles et utilisées dans le ministère.", answer: null },
        { id: 42, question: "J’aide les gens à mieux se comprendre, mais aussi à mieux comprendre leurs relations et la personne de Dieu à travers l’expression artistique.", answer: null },
        { id: 43, question: "Je peux percevoir l’hypocrisie et la duperie avant qu’elles ne soient évidentes pour les autres.", answer: null },
        { id: 44, question: "J’apporte de l’espoir aux autres en leur rappelant et en les dirigeant vers les promesses de Dieu.", answer: null },
        { id: 45, question: "Je suis efficace lorsqu’il s’agit d’adapter le message de l’Evangile pour qu’il soit percutant et pertinent par rapport aux besoins d’un individu.", answer: null },
        { id: 46, question: "Je crois que Dieu va m’aider à accomplir de grandes choses. ", answer: null },
        { id: 47, question: "Je gère bien mon argent de façon à pouvoir faire davantage de dons. ", answer: null },
        { id: 48, question: "J’accomplis volontairement plusieurs petits boulots dans l’église de façon à pouvoir répondre aux divers besoins des autres.", answer: null },
        { id: 49, question: "Je crois sincèrement que le Seigneur conduit vers moi les étrangers qui ont besoin d’entrer en contact avec les autres.", answer: null },
        { id: 50, question: "J’ai conscience d’accomplir un ministère au service des autres lorsque je prie. ", answer: null },
        { id: 51, question: "Je suis dévoué et planifie du temps pour la lecture et l’étude des Ecritures, afin de comprendre pleinement et avec justesse la Vérité Biblique.", answer: null },
        { id: 52, question: "Je suis capable d’ajuster ma manière de diriger, de façon à faire ressortir le meilleur de ce que les gens ont.", answer: null },
        { id: 53, question: "J’aime aider les gens qui sont parfois considérés comme peu méritants ou impossibles à secourir.", answer: null },
        { id: 54, question: "Je dénonce sans hésitation les tendances culturelles, les enseignements ou les événements qui sont en contradiction avec les principes bibliques.", answer: null },
        { id: 55, question: "J’aime apporter des conseils et des directions pour la personne dans son entier : aspects relationnels, émotionnels, spirituels, etc...", answer: null },
        { id: 56, question: "Je suis capable de passer un temps considérable dans l’apprentissage de nouvelles vérités bibliques afin de les communiquer aux autres.", answer: null },
        { id: 57, question: "Je peux facilement sélectionner le mode d’action le plus efficace parmi plusieurs alternatives. ", answer: null },
        { id: 58, question: "Je peux identifier et utiliser efficacement les ressources nécessaires à l’accomplissement de tâches.", answer: null },
        { id: 59, question: "Je m’adapte bien à des cultures et des environnements différents.", answer: null },
        { id: 60, question: "Je suis capable de visualiser la façon dont une chose devrait être construite avant de la construire.", answer: null },
        { id: 61, question: "J’aime trouver des façons nouvelles et dynamiques de communiquer la vérité de Dieu.", answer: null },
        { id: 62, question: "J’ai tendance à percevoir ce qui est juste et ce qui ne l’est pas dans les situations.", answer: null },
        { id: 63, question: "J’arrive à rassurer ceux qui ont besoin de prendre des mesures courageuses quant à leur foi, leur famille, ou leur vie.", answer: null },
        { id: 64, question: "J’invite les non-croyants à accepter Jésus-Christ comme leur Sauveur.", answer: null },
        { id: 65, question: "J’ai confiance en Dieu dans les circonstances où le succès ne peut pas être garanti par l’effort humain seul. ", answer: null },
        { id: 66, question: "Je suis mis au défi de limiter les dépenses liées à mon train de vie afin de faire don de pourcentages plus importants sur mes revenus.", answer: null },
        { id: 67, question: "Je vois l’intérêt spirituel qui existe dans l’exécution de tâches pratiques.", answer: null },
        { id: 68, question: "J’aime créer des endroits où les gens ne se sentient plus seuls.", answer: null },
        { id: 69, question: "Je prie avec confiance car je sais que Dieu agit en répondant à la prière. ", answer: null },
        { id: 70, question: "J’ai une forme de perspicacité, d’intuition, ou je sais simplement que quelque chose est vrai.", answer: null },
        { id: 71, question: "J’établis des objectifs et gère les personnes ainsi que les ressources de façon efficace afin d’atteindre ces objectifs. ", answer: null },
        { id: 72, question: "J’ai beaucoup de compassion pour les gens en souffrance.", answer: null },
        { id: 73, question: "Je perçois la plupart des actions comme étant justes ou mauvaises, et je ressens le besoin de corriger les mauvaises. ", answer: null },
        { id: 74, question: "Je sais apporter fidèlement du soutien et démontrer de l’intérêt pour les autres sur du long terme.", answer: null },
        { id: 75, question: "J’aime avoir une approche méthodique dans mon étude de la Bible.", answer: null },
        { id: 76, question: "Je suis capable d’anticiper les conséquences probables de l’action d’un individu ou d’un groupe. ", answer: null },
        { id: 77, question: "J’aime aider les organisations ou les groupes à devenir plus efficaces.", answer: null },
        { id: 78, question: "Je suis capable d’entrer en relation avec les autres en m’adaptant à leurs différences culturelles.", answer: null },
        { id: 79, question: "J’honore Dieu avec mes talents manuels. ", answer: null },
        { id: 80, question: "Je mets en œuvre différents moyens d’expression artistique pour communiquer la vérité de Dieu.", answer: null },
        { id: 81, question: "Je reçois des confirmations de la part des autres concernant la fiabilité de mes intuitions ou perceptions.", answer: null },
        { id: 82, question: "Je donne de la force à celles et ceux qui chancellent dans leur foi.", answer: null },
        { id: 83, question: "Je dis ouvertement aux gens que je suis chrétien et souhaite qu’ils me posent des questions sur ma foi.", answer: null },
        { id: 84, question: "Je suis convaincu de la présence quotidienne de Dieu et de Son action dans ma vie.", answer: null },
        { id: 85, question: "J’aime savoir que mon soutien financier fait une vraie différence dans la vie et le ministère des personnes qui œuvrent pour Dieu.", answer: null },
        { id: 86, question: "J’aime trouver les petites choses qui doivent être faites et les fais souvent spontanément, sans que l’on me demande de les faire. ", answer: null },
        { id: 87, question: "J’aime divertir les gens et leur ouvrir la porte de ma maison.", answer: null },
        { id: 88, question: "Quand j’entends parler de situations difficiles, je ressens un fardeau sur mon cœur ainsi que le besoin de prier.", answer: null },
        { id: 89, question: "J’ai soudainement connaissance de choses à propos d’autres personnes, mais je ne sais pas d’où ces choses sont venues. ", answer: null },
        { id: 90, question: "J’influence les autres pour qu’ils montrent le meilleur de leurs capacités.", answer: null },
        { id: 91, question: "J’ai la capacité de voir au-delà du handicap ou des problèmes d’une personne pour y percevoir une vie qui a de la valeur aux yeux de Dieu.", answer: null },
        { id: 92, question: "J’aime les gens qui sont honnêtes et qui annoncent la vérité.", answer: null },
        { id: 93, question: "J’aime guider et apporter un soutien pratique à un petit groupe de personnes.", answer: null },
        { id: 94, question: "Je sais communiquer la Parole de Dieu de telle façon à motiver les gens à l’étudier et à vouloir en apprendre plus sur elle. ", answer: null },
        { id: 95, question: "Je donne des conseils pratiques pour aider les personnes à traverser des situations compliquées.", answer: null },
        { id: 96, question: "J’aime apprendre comment les différentes organisations fonctionnent. ", answer: null },
        { id: 97, question: "J’aime être pionnier dans de nouveaux projets.", answer: null },
        { id: 98, question: "Je suis doué pour et j’aime travailler avec mes mains. ", answer: null },
        { id: 99, question: "Je suis créatif et plein d’imagination. ", answer: null },
        { id: 100, question: "Je suis capable d’identifier une prédication, un enseignement ou un message qui ne sont pas en accord avec les vérités bibliques. ", answer: null },
        { id: 101, question: "J’aime motiver les autres à avancer davantage dans leur croissance spirituelle.", answer: null },
        { id: 102, question: "Je parle ouvertement et avec confiance aux autres de ce que Jésus a fait pour moi.", answer: null },
        { id: 103, question: "Je mets régulièrement les autres au défi de placer leur confiance en Dieu.", answer: null },
        { id: 104, question: "Je donne généreusement vu mon engagement dans l’administration de l’église.", answer: null },
        { id: 105, question: "Je suis à l’aise avec le fait d’aider et d’assister les autres à faire leur travail de façon plus efficace.", answer: null },
        { id: 106, question: "Je fais tout ce que je peux pour aider les personnes à se sentir intégrées.", answer: null },
        { id: 107, question: "Je me sens honoré lorsque des personnes me demandent de prier pour elles.", answer: null },
        { id: 108, question: "Je découvre des vérités bibliques importantes lors de la lecture et de l’étude des Ecritures, qui ensuite bénéficient aux autres dans le corps de Christ.", answer: null },
        { id: 109, question: "Je suis capable d’émettre une vision à laquelle les gens veulent prendre part.", answer: null },
        { id: 110, question: "J’aime apporter de l’espoir et de la joie aux personnes qui passent par des circonstances difficiles.", answer: null },
        { id: 111, question: "J’annonce la vérité de l’Evangile de Dieu, même dans des endroits où elle risque d’être impopulaire et où les gens auront du mal à l’accepter.", answer: null },
        { id: 112, question: "Je suis capable de ramener les chrétiens rétrogrades à la foi et à la communion fraternelle avec douceur.", answer: null },
        { id: 113, question: "Je suis capable de présenter aux autres des principes et des compétences à un niveau où il leur est facile de les assimiler et de les mettre en pratique dans leur vie. ", answer: null },
        { id: 114, question: "Je suis capable de vivre concrètement des vérités bibliques que les autres vont trouver pratiques et utiles.", answer: null },
        { id: 115, question: "Je suis capable de voir venir un évènement, d’anticiper des problèmes potentiels et de développer des plans de secours. ", answer: null },
        { id: 116, question: "Je suis dans la capacité d’orchestrer ou de superviser plusieurs ministères d’églises.", answer: null },
        { id: 117, question: "Je suis capable de concevoir et de construire des choses qui aident l’église.", answer: null },
        { id: 118, question: "J’ai régulièrement besoin d’être seul pour réfléchir et pour développer mon imagination.", answer: null },
        { id: 119, question: "Je peux sentir lorsque des forces démoniaques sont à l’œuvre dans une personne ou une situation.", answer: null },
        { id: 120, question: "Je suis capable de bousculer ou de reprendre les autres de façon à stimuler leur croissance spirituelle.", answer: null },
        { id: 121, question: "Je recherche des opportunités pour parler de questions spirituelles avec les non-croyants.", answer: null },
        { id: 122, question: "Je suis capable d’aller de l’avant malgré un manque de soutien quand je sens l’approbation et la bénédiction de Dieu sur un projet. ", answer: null },
        { id: 123, question: "Je crois avoir reçu des ressources en abondance de façon à pouvoir donner davantage pour l’œuvre du Seigneur. ", answer: null },
        { id: 124, question: "J’utilise spontanément et avec joie mes compétences naturelles ou acquises pour aider là où il y a des besoins.", answer: null },
        { id: 125, question: "J’ai la capacité de mettre les gens à l’aise même dans des environnements qui ne leur sont pas familiers.", answer: null },
        { id: 126, question: "Je vois souvent des résultats précis en réponse directe à mes prières.", answer: null },
        { id: 127, question: "Je partage avec confiance mes connaissances et mes intuitions avec les autres.", answer: null },
        { id: 128, question: "Je comprends vers quelle direction il faut se diriger et aide les autres à aller dans ce sens.", answer: null },
        { id: 129, question: "J’aime faire des choses pratiques pour celles et ceux qui sont dans le besoin.", answer: null },
        { id: 130, question: "Je me sens poussé à dénoncer le péché partout où je le vois et à amener les gens à se repentir. ", answer: null },
        { id: 131, question: "J’aime contribuer de façon patiente mais aussi ferme au développement des autres en tant que croyants. ", answer: null },
        { id: 132, question: "J’aime expliquer des faits aux gens de façon à favoriser leur croissance spirituelle et personnelle.", answer: null },
        { id: 133, question: "J’ai des intuitions quant à la résolution de problèmes que les autres ne voient pas. ", answer: null },
      ],
      responseOptions: [
        { value: 0, color: 'blue', density: 'default' },
        { value: 1, color: 'blue-lighten-3', density: 'comfortable' },
        { value: 2, color: 'purple-lighten-3', density: 'comfortable' },
        { value: 3, color: 'purple', density: 'default' }
      ],
      questionsLength: 133,
      dialog: false,
      nom: null,
      prenom: null,
      email: null,
    }
  },
}
</script>

<style>
.v-card.inactive {
  opacity: 0.3;
}
</style>