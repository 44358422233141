import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/Home.vue'
import ShapeTest from './components/Shape.vue'
import GospelTest from './components/Gospel.vue'

const routes = [
    { path: '/', component: HomePage },
    { path: '/shape', component: ShapeTest },
    { path: '/gospel', component: GospelTest },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router