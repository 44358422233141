<template>
    <h1 class="text-center">Nos tests</h1>

    <v-row align-content>
        <v-col cols="12" md="6">
            <v-btn @click="goToShape()" size="x-large" block flat elevation="5" color="yellow">
                Quel est mon don ?
            </v-btn>
        </v-col>
        <v-col cols="12" md="6">
            <v-btn size="x-large" block elevation="5" color="orange" @click="goToGospel()">
                Quel est mon style d'évangelisation ?
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default
    {
        name: 'HomePage',
        methods: {
            goToShape() {
                this.$router.push('shape');
            },
            goToGospel() {
                this.$router.push('gospel');
            }
        }
    }
</script>